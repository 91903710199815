exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-customer-tsx": () => import("./../../../src/templates/customer.tsx" /* webpackChunkName: "component---src-templates-customer-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-integration-tsx": () => import("./../../../src/templates/integration.tsx" /* webpackChunkName: "component---src-templates-integration-tsx" */),
  "component---src-templates-metric-tsx": () => import("./../../../src/templates/metric.tsx" /* webpackChunkName: "component---src-templates-metric-tsx" */)
}

